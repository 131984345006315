const $ = require('jquery');
global.$ = global.jQuery = $;

require('bootstrap');
require('bootstrap-sass');

const html2canvas = require('html2canvas');
global.html2canvas = html2canvas;

// TODO: раскомментировать, есть не работает вариант copyFiles из webpack.confing.js
// const imagesContext = require.context('../images', true, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);
// imagesContext.keys().forEach(imagesContext);